export default {
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    isClosed() {
      return !this.isOpened;
    },
    isOpenable() {
      return true;
    },
    isCloseable() {
      return true;
    },
  },
  emits: ['open', 'close', 'toggle'],
  methods: {
    onOpen() {},
    onClose() {},
    async open() {
      if (this.isOpened || !this.isOpenable) return;
      this.isOpened = true;
      await this.onOpen();
      this.$emit('open');
    },
    async close() {
      if (this.isClosed || !this.isCloseable) return;
      this.isOpened = false;
      await this.onClose();
      this.$emit('close');
    },
    async toggle() {
      if (this.isOpened) {
        await this.close();
        this.$emit('toggle', false);
      } else {
        await this.open();
        this.$emit('toggle', true);
      }
    },
  },
};
